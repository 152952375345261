/* src/components/KLA.css */

.kla-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 220px;
  background-color: #f4f4f4;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100vh;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar li a {
  color: #333;
  text-decoration: none;
}

.sidebar li:hover {
  background-color: #ddd;
}

.sidebar li svg {
  margin-right: 10px;
}

.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  height: 100vh;
}

.header-section {
  margin-bottom: 20px;
}

.kla-title {
  border-bottom: 2px solid #8b0000;
  margin-bottom: 10px;
}

.kla-title h1 {
  font-size: 20px;
  color: #8b0000;
}

.kla-title p {
  font-size: 14px;
  color: #555;
}
