/* Footer 컨테이너 스타일 */
.footer {
  background-color: #f5ebe3; /* 배경색 */
  padding: 20px 40px;
  text-align: right; /* 텍스트 오른쪽 정렬 */
  font-family: "Arial", sans-serif;
  font-size: 0.9em;
  color: #5d4631; /* 텍스트 색상 */
  border-top: 1px solid #e0d6c9; /* 상단 경계선 */
  
  position: relative; /* 기본 흐름에 따라 배치 */
  width: 100%; /* 페이지 전체 너비 */
  box-sizing: border-box; /* 패딩 포함 */
}

/* Footer 내부 텍스트 영역 */
.footer-info {
  line-height: 1.6; /* 텍스트 줄 간격 */
}

.footer-info a {
  color: #5d4631; /* 링크 색상 */
  text-decoration: none;
}

.footer-info a:hover {
  text-decoration: underline; /* 링크 호버 시 밑줄 */
}
