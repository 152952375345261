/* src/components/Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    background-color: #f5f5f5;
    margin: 0;
  }
  
  .login-box {
    background-color: white;
    border-radius: 10px;
    padding: 20px 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
  }
  
  .login-box h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #45a049;
  }
  
  .signup-link {
    margin-top: 15px;
    font-size: 14px;
  }
  
  .signup-link a {
    color: #4caf50;
    text-decoration: none;
  }
  
  .signup-link a:hover {
    text-decoration: underline;
  }
  
  /* Error message 스타일 */
  .error-message {
    color: red;
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
  }