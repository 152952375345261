/* src/components/KLA/KLAHistory.css */

/* 전체 컨테이너 스타일 */
.kla-history {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 100%; /* 가로 공간 최대 사용 */
}

/* 제목 스타일 */
.kla-history h2 {
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* 테이블 스타일 */
.history-table {
  width: auto; /* 테이블 너비를 내용에 맞게 */
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden; /* 테두리가 둥근 스타일 적용 */
  margin-left: 0; /* 왼쪽 정렬 */
}

/* 테이블 헤더 스타일 */
.history-table th {
  background-color: #007bff;
  color: #fff;
  padding: 12px;
  text-align: left;
  font-weight: bold;
  border: 1px solid #ddd;
  min-width: 120px; /* 각 칼럼 최소 너비 설정 */
}

/* 테이블 본문 스타일 */
.history-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  color: #555;
  min-width: 120px; /* 각 칼럼 최소 너비 설정 */
}

/* 짝수 행 배경색 */
.history-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* 홀수 행 배경색 */
.history-table tr:nth-child(odd) {
  background-color: #fff;
}

/* 행 호버 효과 */
.history-table tr:hover {
  background-color: #f1f1f1;
  transition: background-color 0.3s ease;
}

/* 강조 텍스트 */
.history-table td strong {
  color: #333;
  font-weight: bold;
}

/* 휴지통 아이콘 스타일 */
.delete-icon {
  cursor: pointer;
  color: #ff4d4f;
  font-size: 18px;
  transition: transform 0.2s ease;
}

.delete-icon:hover {
  transform: scale(1.2);
  color: #d11a2a;
}

.pdf-icon {
  color: #e74c3c; /* PDF 관련 빨간색 */
  cursor: pointer;
  font-size: 1.2em;
  transition: color 0.2s;
}

.pdf-icon:hover {
  color: #c0392b; /* hover 시 더 짙은 빨간색 */
}