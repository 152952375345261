/* HorizontalBarChart.css */

.chart-container {
  position: relative;
  width: 100%;
  height: 20px;
}

.line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  background-color: black;
  transform: translateY(-50%);
}

.marker {
  position: absolute;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.ticks {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
}

.tick {
  position: absolute;
  top: 50%;
  width: 4px;
  height: 30px;
  background-color: black;
  transform: translateY(-50%);
}

.tick2 {
  position: absolute;
  top: 50%;
  width: 2px;
  height: 20px;
  background-color: gray;
  transform: translateY(-50%);
}
