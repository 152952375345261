.data-table {
  width: 800px;
  border-collapse: collapse;
  margin: 20px 0;
  table-layout: fixed;
}

.data-table th,
.data-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.data-table thead th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.data-table thead th:first-child {
  width: 5%;
}

.data-table thead th:nth-child(2) {
  width: 17%;
}

.data-table thead th:nth-child(3) {
  width: 8%;
}

.data-table thead th[colspan="9"] {
  width: 70%;
}

.data-table tbody td {
  vertical-align: middle;
  height: 30px;
}

.data-table .position-header th {
  border-left: none;
  border-right: none;
}

.data-table .position-header th:first-child {
  border-left: 1px solid black;
}

.data-table .position-header th:last-child {
  border-right: 1px solid black;
}

.data-table .position-header th {
  width: calc(100% / 9);
}
