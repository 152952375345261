.klaintro-top {
  padding-bottom: 40px;
}

.panel {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
}

.panel-heading {
  background-color: #fff;
  color: #333;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-title {
  margin: 0;
  font-size: 18px;
}

.panel-body {
  padding: 15px;
  color: #666;
  line-height: 1.6;
}

.well {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  margin-top: 20px;
  margin-bottom: 40px;
}

/* 가로로 배치된 버튼을 위한 스타일 */
.toggle-buttons {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.toggle-button {
  padding: 10px 20px; /* 상하 10px, 좌우 20px */
  font-size: 16px; /* 버튼 텍스트 크기 */
  background-color: #0056b3; /* 버튼 배경색 (원하는 색으로 변경 가능) */
  color: #fff; /* 텍스트 색상 */
  border: none; /* 테두리 제거 */
  border-radius: 4px; /* 둥근 테두리 */
  cursor: pointer; /* 마우스 커서 포인터로 변경 */
  width: 250px; /* 버튼의 고정 너비 설정 */
  text-align: center; /* 버튼 텍스트 가운데 정렬 */
  margin-right: 20px;
}

.toggle-button:hover {
  background-color: #003d7a; /* 마우스 오버 시 배경색 변경 */
}

/* 선택된 버튼에 대한 스타일 */
.toggle-button.active {
  background-color: #003d7a;
  font-weight: bold;
}

.bullet-list ul {
  list-style-type: square; /* 네모난 불렛 추가 */
  padding-left: 20px; /* 첫 번째 리스트 제목과 동일한 패딩 */
}

.bullet-list .sub-list {
  list-style-type: circle; /* 기본 불렛을 제거 */
  padding-left: 40px; /* 두 번째 리스트에 더 큰 패딩 적용 */
}