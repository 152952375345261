/* App.css */

/* 리액트로 만든 웹사이트 전체에 대한 설정 */
#root {
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;  
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-container {
  flex: 1;
  margin-bottom: 0; /* 불필요한 여백 제거 */
  padding-bottom: 0; /* 필요 시 추가 */
}

header {
  background-color: #282c34;
  padding: 20px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-menu,
.right-menu {
  display: flex;
  align-items: center;
}

.left-menu {
  gap: 40px;
}

.right-menu {
  gap: 40px;
}

h2 {
  margin: 0;
  font-size: 1.3em; /* h2 크기 조정 */
}

a {
  color: white;
  text-decoration: none; /* 밑줄 제거 */
}

a:hover {
  text-decoration: none; /* hover 시 밑줄 제거 */
}

/* 이메일 주소와 로그아웃 버튼을 흰색으로 표시 */
.right-menu span {
  color: white; /* 흰색으로 표시 */
  font-weight: normal;
  cursor: default; /* 기본 커서 */
}

.right-menu span:last-child {
  color: white; /* 흰색으로 표시 */
  cursor: pointer;
}

.right-menu span:last-child:hover {
  text-decoration: underline; /* hover 시 밑줄 추가 */
}